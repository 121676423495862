import React from "react"
import * as styles from "./Introsection.module.scss"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Container } from "react-bootstrap"
import { Link } from "gatsby"

const AIStatisticsTrend = ({ strapiData, predicto, cheer, easyFill }) => {
  return (
    <Container>
      <div>
        <div className={styles.sectionContainer}>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={5}
              className={styles.headingContainer}
            >
              <h2
                className={styles.headingIntro}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <p
                className={styles.descriptionText}
                dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={7}
              className={styles.headingContainer}
            >
              <p
                className={styles.paragraphAi}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
              <div className={styles.cardFooter}>
                <Link
                  to={"/contact-us/"}
                  className={`${styles.newBtnn} `}
                >
                  {strapiData?.buttons[0]?.title}
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  )
}

export default AIStatisticsTrend
