import React from "react"
import Container from "react-bootstrap/Container"

import * as styles from "./Section.module.scss"


function Section({strapiData}) {
  return (
    <div className={styles.sectionMain}>
    <Container>
      <div className={styles.section}>
        <h2 className={styles.heading1} dangerouslySetInnerHTML={{__html:strapiData.title}}></h2>
        <p className={styles.para1} dangerouslySetInnerHTML={{__html:strapiData.subTitle}}></p>
      </div>
    </Container>
    </div>
  )
}

export default Section
