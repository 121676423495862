import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./KeyChalanges.module.scss"
import connectingLine from "../../images/invocom/arrow.webp"

function KeyChallengesSolution({
  strapiData,
  cheer,
  atomic,
  ocr,
  live,
  weapon,
  docpod,
}) {
  return (
    <div className={styles.sectionMain}>
    <div
      className={`${cheer ? styles.cheerBg : ""} ${
        atomic ? styles.atomicBg : ""
      } ${ocr ? styles.ocrBg : ""}  ${docpod ? styles.docpod : ""} `}
    >
      <Container>
        <div
          className={`${styles.keyChallengesContainer} ${
            cheer ? styles.cheerBg : ""
          } ${atomic ? styles.atomicBg : ""} ${ocr ? styles.ocrBgPd : ""} ${
            live ? styles.liveBg : ""
          } ${weapon ? styles.weaponBg : ""} ${docpod ? styles.docpod : ""}`}
        >
          <h2
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div className={styles.challengesGrid}>
            {strapiData?.cards?.map((item, index) => (
              <div key={index} className={styles.challengeRow}>
                <div
                  className={`${styles.leftBox} ${
                    atomic ? styles.leftBoxBorder : ""
                  } ${cheer ? styles.cheerleftBoxBorder : ""}`}
                >
                  <div className={styles.iconWrapper}>
                    <div className={`${styles.icon} ${styles[item.icon]}`}>
                      <img src={item?.image1[0]?.localFile?.publicURL} />
                    </div>
                  </div>
                  <div className={styles.titleWrapper}>
                    <h3>{item.title}</h3>
                  </div>
                </div>

                <div className={styles.connectingLine}>
                  <img src={connectingLine} alt="connecting line" />
                </div>

                <div
                  className={`${styles.rightBox} ${
                    atomic ? styles.rightBoxBorder : ""
                  } ${cheer ? styles.CheerrightBoxBorder : ""}`}
                >
                  <div className={styles.content}>
                    <div className={styles.challengeContent}>
                      <h4>Challenge</h4>
                      <p>{item.subTitle}</p>
                    </div>
                    <div className={styles.solutionContent}>
                      <h4>Solution</h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item?.description?.description,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
    </div>
  )
}

export default KeyChallengesSolution
