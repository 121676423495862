// extracted by mini-css-extract-plugin
export var backgroundImg = "Banner1-module--backgroundImg--c4edf";
export var bannerCurve = "Banner1-module--bannerCurve--7d061";
export var bannerHeading = "Banner1-module--bannerHeading--b3eab";
export var bannerText = "Banner1-module--bannerText--18609";
export var cheerHead = "Banner1-module--cheerHead--8e9a4";
export var dedicated = "Banner1-module--dedicated--8f1be";
export var dedicatedBanner = "Banner1-module--dedicatedBanner--eec76";
export var dedicatedHeadingStatic = "Banner1-module--dedicatedHeadingStatic--42b79";
export var desktopImage = "Banner1-module--desktopImage--2f2bb";
export var easyFillHead = "Banner1-module--easyFillHead--37710";
export var headingIntro = "Banner1-module--headingIntro--cd6b5";
export var innerDedicated = "Banner1-module--innerDedicated--a32e9";
export var mobileImage = "Banner1-module--mobileImage--c2942";
export var paragraphAi = "Banner1-module--paragraphAi--0523c";