import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./Center.module.scss"

function Conclusion() {
  return (
    <div className={styles.sectionMain1}>
      <Container>
        <div className={styles.laptopImg}>
          <img
            src={
              "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_12_2c41a8b768.webp"
            }
            alt="laptop"
            decoding="async"
            loading="lazy"
          />
        </div>
      </Container>
    </div>
  )
}

export default Conclusion
