import React from "react"
import * as styles from "./Efficiency.module.scss"
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
function Enhancements({ strapiData }) {
  // const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={styles.enhancements}>
      <Container>
        <h2
          className={styles.mainHeading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        ></h2>
        <p className={styles.subHeading}>
          Docpod Isn't Just For Individual Healthcare Providers – It's A Tool
          That Can Benefit Entire Industries:
        </p>
        <Row className="align-items-center">
          <Col lg={6}>
            {/* <GatsbyImage
              placeholder="blurred"
              decoding="async"
              loading="lazy"
              image={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/Screenshot_2024_12_20_at_1_58_43_PM_1_b7a360e47e.webp"
              }
              alt={"img"}
            /> */}
            <img
              decoding="async"
              loading="lazy"
              src="https://invozone-backend.s3.us-east-1.amazonaws.com/Screenshot_2024_12_20_at_1_58_43_PM_1_b7a360e47e.webp"
            />
          </Col>

          <Col lg={6}>
            {strapiData?.cards?.map((e, i) => (
              <div className={styles.cards}>
                <h3
                  className={styles.blueDot}
                  dangerouslySetInnerHTML={{
                    __html: e?.title,
                  }}
                ></h3>

                <p
                  className={styles.mainDes}
                  dangerouslySetInnerHTML={{
                    __html: e?.subTitle,
                  }}
                />
              </div>
            ))}
            <div className={styles.btnContainer}>
              <Link to={"/contact-us/"} className={`${styles.newBtnn} `}>
                {strapiData?.buttons[0]?.title}
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Enhancements
