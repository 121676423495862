import React from "react"
import * as styles from "./Technologiesweuse.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { Col } from "react-bootstrap"

// Mock data structure
const mockData = {
  cards: [
    {
      title: "Industry",
      subTitle: "On Demand",
      
    },
    {
      title: "Services",
      subTitle: "Web App",
    
    },
    {
      title: "Technologies We Use",
      subTitle: "",
      jsonData: {
        caseServices: {
          invocom_caseStudy: {
            skills: ["Solidity", "ReactJs", "NodeJs", "MangoDB", "Web3"]
          }
        }
      }
    },

  ]
}

const TrustInvoZone = ({strapiData}) => {
  return (
    <div className={styles.Trust}>
      <div className={styles.redline}>
        <img
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/line_vector_c415196118.png"
          }
          className="w-100 h-100"
          alt="InvoGames"
        />
      </div>
      <Container>
        <Row>
          {strapiData?.cards.map((e, i) => (
            <Col key={i} xl={i === strapiData.cards.length - 1 ? 6 : 3} md={12} xs={12}>
              <p className={styles.firstHeading}>{e.title}</p>
              <span
                className={styles.firstSpan}
                dangerouslySetInnerHTML={{
                  __html: e.subTitle,
                }}
              ></span>
              {e.jsonData?.caseServices?.invocom_caseStudy?.skills && (
                <div className={styles.firstSpan}>
                  {e.jsonData?.caseServices?.invocom_caseStudy.skills.map(
                    (skill, index) => (
                      <span key={index} className={styles.roundedButton}>
                        {skill}
                      </span>
                    )
                  )}
                </div>
              )}
            </Col>
          ))}
        </Row>
        <hr className={styles.line} />
      </Container>
    </div>
  )
}

export default TrustInvoZone