// extracted by mini-css-extract-plugin
export var arrowAnimation = "Efficiency-module--arrowAnimation--c5c79";
export var blue = "Efficiency-module--blue--7fbcc";
export var blueDot = "Efficiency-module--blueDot--181f2";
export var btnContainer = "Efficiency-module--btnContainer--1f0c7";
export var cards = "Efficiency-module--cards--4598d";
export var enhancements = "Efficiency-module--enhancements--69db1";
export var mainHeading = "Efficiency-module--mainHeading--f27fa";
export var newBtnn = "Efficiency-module--newBtnn--25173";
export var subHeading = "Efficiency-module--subHeading--1e99c";
export var yellow = "Efficiency-module--yellow--7a0d6";