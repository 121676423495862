import React from "react"
import * as styles from "./Center.module.scss"

function Conclusion() {
  return (
    <>
      <div className={styles.sectionMain}>
        <div className={styles.laptopImg1}>
          <img
            src={
              "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000008173_7ddd70de81.webp"
            }
            alt="laptop"
            decoding="async"
            loading="lazy"
          />
        </div>
        <div className={styles.laptopImg2}>
          <img
            src={
              "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1686557396_8485bd3b5d.webp"
            }
            alt="laptop"
            decoding="async"
            loading="lazy"
          />
        </div>
      </div>
    </>
  )
}

export default Conclusion
