// extracted by mini-css-extract-plugin
export var CheerrightBoxBorder = "KeyChalanges-module--CheerrightBoxBorder--3873d";
export var aiIcon = "KeyChalanges-module--ai-icon--1c8cd";
export var atomicBg = "KeyChalanges-module--atomicBg--54871";
export var challengeContent = "KeyChalanges-module--challengeContent--62ba0";
export var challengeRow = "KeyChalanges-module--challengeRow--3479f";
export var challengesGrid = "KeyChalanges-module--challengesGrid--5a184";
export var cheerBg = "KeyChalanges-module--cheerBg--8f9cd";
export var cheerleftBoxBorder = "KeyChalanges-module--cheerleftBoxBorder--c50bb";
export var connectingLine = "KeyChalanges-module--connectingLine--7b2fe";
export var content = "KeyChalanges-module--content--fd025";
export var docpod = "KeyChalanges-module--docpod--62db6";
export var graphIcon = "KeyChalanges-module--graph-icon--7d0fd";
export var icon = "KeyChalanges-module--icon--7f5d3";
export var iconWrapper = "KeyChalanges-module--iconWrapper--3bd33";
export var keyChallengesContainer = "KeyChalanges-module--keyChallengesContainer--59c56";
export var leftBox = "KeyChalanges-module--leftBox--a0429";
export var leftBoxBorder = "KeyChalanges-module--leftBoxBorder--32120";
export var liveBg = "KeyChalanges-module--liveBg--a1478";
export var ocrBg = "KeyChalanges-module--ocrBg--2843d";
export var ocrBgPd = "KeyChalanges-module--ocrBgPd--a4dbc";
export var rightBox = "KeyChalanges-module--rightBox--259e8";
export var rightBoxBorder = "KeyChalanges-module--rightBoxBorder--b77a2";
export var sectionMain = "KeyChalanges-module--sectionMain--349bd";
export var solutionContent = "KeyChalanges-module--solutionContent--19f6b";
export var title = "KeyChalanges-module--title--c2a48";
export var titleWrapper = "KeyChalanges-module--titleWrapper--aafa2";
export var weaponBg = "KeyChalanges-module--weaponBg--1c160";
export var webglIcon = "KeyChalanges-module--webgl-icon--64044";